@import '@app/mf-nucleus.scss';

.background-margin {
    margin-top: 70px;
}

.button-pos {
    display: block;
    margin-top: 20px;
}

.mt70 {
    margin-top: 70px;
}

.outer-margin {
    margin-left: 85px;
    margin-top: 44px;
}

.common-font {
    font-family: Montserrat, sans-serif;
}

.mb37 {
    margin-bottom: 37px;
}

.footer-margin {
    margin-top: 30px;
}

.margin-below {
    margin-bottom: 30px;
}