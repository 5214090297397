@import '@app/mf-nucleus.scss';

.header-container {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    max-width: 1248px;
    padding: 66px 3%;
    width: 100%;
}

@include breakpoint(tablet) {
    .header-container {
        padding: 66px 50px;
    }
}

@include breakpoint(mobile) {
    .header-container {
        padding: 66px 40px;
    }
}