//td do : mf_nucleus
.footer-padding {
 padding: 32px 0;
}
.devider {
 border-top: 3px solid #f1f1f1;
 width: 100%;
}
.footer-link {
 color: inherit;
 text-decoration: inherit;
}
