@import '@app/mf-nucleus.scss';

.text {
    font-family: Montserrat, Arial, sans-serif;
    font-style: normal;
}

.bold {
    font-weight: 700;
}

.normal {
    font-weight: normal;
}

.dark {
    color: $mf-text-dark;
}

.light {
    color: $mf-white;
}

.grey {
    color: #b7b7b7;
}

.purple {
    color: #7988ff;
}