@import '@app/mf-nucleus.scss';

.footer-container {
    background: $mf-background-1;
    border-radius: 64px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px !important;
    max-width: 1248px;
    padding: 66px 0;
    width: 100%;

    >div {
        padding: 0 48px;
    }

    .links a {
        color: $mf-purple-2;
        font-size: 16px;
        font-weight: bold;
        margin: 12px 0;
        text-decoration: none;
    }

    .logo-wrapper {
        margin-bottom: 31px;
        width: 162px;
    }
}

.social-btn-container {
    margin-top: 40px !important;

    div {
        align-items: center;
        background-color: $mf-white;
        border-radius: 40px;
        display: flex;
        height: 48px;
        justify-content: center;
        margin-right: 16px;
        width: 48px;
    }
}

@include breakpoint(tablet) {
    .footer-container {
        border-radius: unset;
        margin-bottom: 0 !important;

        .links {
            margin-top: 32px;
        }
    }
}