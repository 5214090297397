@import '@app/mf-nucleus.scss';

.login-desc-wrapper {
    padding: 57px 30px 0 91px;
    width: 392px;

    .line {
        background: $mf-purple-3;
        border: 3px solid $mf-purple-3;
        height: 0;
        position: absolute;
        top: 130px;
        width: 65px;
    }
}

.logo-position {
    height: fit-content;
    overflow: hidden;
    position: absolute;
    top: 80px;
    width: fit-content;

    .logo-image {
        height: auto;
        position: relative;
        width: 156px;
    }
}