.public-page-layout-wrapper {
 display: flex;
 justify-content: center;
 margin-bottom: 140px;

 .public-page-layout-container {
  display: flex;
  max-width: 1013px;
 }
}
