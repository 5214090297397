@import '@app/mf-nucleus.scss';

.data-table-cell {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
    height: 90px;
    line-height: 30px;
    max-width: 100px;
    min-width: 100px;
}

.table-header:nth-child(1) {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
}

.table-header:nth-last-child(1) {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
}

.w200 {
    width: 200px;
}

.hover-row:hover {
    background-color: $mf-gray-2;
    cursor: pointer;
}

.hover-row:hover .show-on-hover {
    display: block;
}

.hover-row:hover .hide-on-hover {
    display: none;
}

.hide {
    display: none;
}

.deliver-btn-pos {
    position: relative;
    right: 50px;
}

/* TODO: need to be seperated with BaiscMyOrderRow.module.css along with other styles used in BaiscMyOrderRow.tsx. need dicussion with Yasmin */

.myorder-detail-form {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.unblurred-row {
    position: relative;
    z-index: 101;
}

.myorder-row-button button {
    align-items: center;
    display: flex;

    &:active:focus {
        background-color: $mf-purple-3;
    }
}

.myorder-row-button button div,
.myorder-row-button button img {
    margin: 0 5px 0 -5px;
}

.table {
    border-collapse: collapse;
    max-width: 745px;
    overflow: hidden;

    thead {
        display: table;
        width: 100%;
    }

    tr {
        display: table;
        width: 100%;
    }
}

.table-cell {
    font-size: 20px;
}