@import '@app/mf-nucleus.scss';

.main-wrapper {
    background: $mf-background-1;
    border-radius: 30px;
    margin-bottom: 126px;
    max-width: 1248px;
}

.main-container {
    padding: 56px 0 39px 96px;
    z-index: 5;

    .main-text div {
        font-size: 40px;
        line-height: 56px;
        white-space: pre-line;
    }

    .donate-btn button {
        border-radius: 32px;
        box-shadow: none;
        color: $mf-white;
        font-size: 20px;
        height: 64px;
        margin: 37px 0;
        max-width: 287px;
        width: 100%;
    }

    .sub-text {
        color: #4a4a4a;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin: 6px 0;
    }

    .sub-text a {
        color: $mf-purple-2;
        margin-left: 5px;
    }
}

.main-image-wrapper {
    height: 715px;
    max-width: 1248px;
    position: absolute;
    top: 100px;

    img {
        float: right;
        max-width: stretch;
    }
}

@include breakpoint(mobile) {
    .main-container {
        padding: 0 40px 232px;

        .main-text div {
            font-size: 9.5vw;
            line-height: 11vw;
        }

        .donate-btn button {
            max-width: none;
        }

        .sub-text {
            flex-direction: column;

            a {
                margin-left: unset;
            }
        }
    }

    .main-image-wrapper {
        height: unset;
        position: relative;
        top: -200px;
    }
}

@include breakpoint(tablet) {
    .main-wrapper {
        border-radius: unset;
    }
}