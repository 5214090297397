.hr-display {
 background: #e4e7ff;
 border: 2px solid #e4e7ff;
 border-radius: 2px;
 display: table-caption;
 height: 0;
 margin-bottom: 53px;
 margin-top: 59px;
 width: 65px;
}

.logo-container {
 img {
  max-height: 100%;
  max-width: 100%;
 }
}
