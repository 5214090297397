.sidebar-container {
 left: 0;
 position: fixed;
 top: 0;
 width: 100%;
 z-index: 100;
}

.sidebar-wrapper {
 bottom: 0;
 height: 100%;
 left: 0;
 position: absolute;
 right: 0;
 top: 0;
 width: 100%;
}
