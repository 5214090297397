@import '@app/mf-nucleus.scss';

.header-text {
    font-size: 40px;
    margin: 0 1%;

    div {
        font-size: 40px;
        margin: 20px 0 30px;
    }
}

.body-text span {
    display: block;
    font-size: 22px;
    line-height: 1.5;
    margin: 20px 1.5%;
}

.open-source-container {
    padding: 1%;
}

.open-source-item-container {
    border: #d3d3d3 solid 1px;
    border-radius: 4px;
    padding: 20px;

    hr {
        border: #d3d3d3 solid 0.3px;
        height: 0;
        width: 100%;
    }

    a {
        color: $mf-purple-1;
        font-weight: bold;
        margin-top: 13px;
        text-decoration: none;
    }
}

.open-source-item-container .language,
.open-source-item-container .desc {
    color: #808080;
    line-height: 1.45;
}

.attribution {
    color: #808080;
    font-size: 15px;
    text-align: end;
}