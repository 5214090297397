.size-body-large {
 font-size: 24px;
 line-height: 48px;
}

.size-body-default {
 font-size: 16px;
 line-height: 27px;
}

.size-body-small {
 font-size: 14px;
 line-height: 20px;
}

.white-space-default {
 white-space: pre-line;
}
