@import '@app/mf-nucleus.scss';

.progress-bar {
    margin-top: 32px;
}

.bolded {
    font-weight: bold;
}

.section {
    padding-bottom: 20px;
    padding-top: 20px;
}

.section>button {
    margin-bottom: 12px;
    margin-right: 12px;
}

.title {
    margin-bottom: 4px;
}

.body {
    margin-bottom: 20px;
}

.divider {
    background: $mf-white;
    border: 1px solid $mf-white;
    border-radius: 1px;
    width: 65px;
}