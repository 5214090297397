/** 
 * Remove Arrows/Spinners
 * Source: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp 
 */

input::-webkit-inner-spin-button {
 appearance: none;
 margin: 0;
}

.no-input-stepper {
 /* Chrome, Safari, Edge, Opera */
 > input::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
 }

 /* Firefox */
 > input[type='number'] {
  appearance: textfield;
 }
}

.form-row {
 align-items: center;
 display: flex;
 flex-direction: row;
 justify-content: flex-end;
 padding-bottom: 12px;

 &:last-child {
  padding: 0;
 }
}

.form-row-item {
 padding-right: 10px;

 &:first-child {
  flex-grow: 1;
  text-align: left;
 }

 &:last-child {
  padding: 0;
 }
}

.bolded {
 font-weight: bold;
}
