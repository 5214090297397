.carousel-container {
 overflow-x: hidden;
 padding: 40px;
 position: relative;
 top: -70px;
}

.slick-track {
 display: flex;
 width: 100vw;
}

.slick-track img {
 border-radius: 22px;
 height: 310px;
 margin: auto;
 width: auto;
}

.slick-track *:focus {
 outline: none;
}

.slick-slide {
 padding: 0 10px;
}
