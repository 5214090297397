@import '@app/mf-nucleus.scss';

.our-impact-wrapper {
    max-width: 1248px;
    padding: 126px 96px;

    .text-large div {
        font-size: 40px;
        line-height: 56px;
        white-space: pre-line;
    }

    a {
        color: $mf-purple-2;
    }
}

.our-impact-container-1 {
    padding-right: 75px;
}

.our-impact-container-2 {
    padding-left: 75px;

    .box {
        margin-bottom: 68px;
    }
}

@include breakpoint(mobile) {
    .our-impact-wrapper {
        margin-top: -200px;

        .text-large div {
            white-space: unset;
        }
    }
}

@include breakpoint(tablet) {
    .our-impact-wrapper {
        padding: 32px 37px;
    }

    .our-impact-container-1 {
        padding: unset;

        .text-large div {
            font-size: 35px;
            margin-bottom: 32px;
        }
    }

    .our-impact-container-2 {
        padding: unset;

        .text-large div {
            font-size: 30px;
            line-height: 46px;
            margin-bottom: 32px;
        }

        .box {
            margin-bottom: 30px;

            span {
                font-size: 20px;
                line-height: 40px;
            }
        }
    }
}