.size-header-large {
 font-size: 60px;
 line-height: 70px;
}

.size-header-default {
 font-size: 24px;
 line-height: 40px;
 padding-bottom: 6px;
}

.size-header-small {
 font-size: 20px;
 line-height: 30px;
}

.size-header-xsmall {
 font-size: 14px;
 line-height: 20px;
}
