@import '@app/mf-nucleus.scss';

.close-button {
    background: $mf-white url('./images/close.svg') center no-repeat;
    background-size: auto;
    border-radius: 24px;
    box-shadow: 0 10px 20px $mf-dismiss-shadow;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    height: 48px;
    width: 48px;
}