@import '@app/mf-nucleus.scss';

.request-form {
    align-items: center;
    color: $mf-white;
    display: inline-block;
    height: 60px;
    height: fit-content;
    justify-content: center;
    width: 407px;

    input,
    select {
        margin: 20px 10px 20px 0;
        width: 379px;
    }

    select {
        appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, #263b59 50%),
            linear-gradient(135deg, #263b59 50%, transparent 50%),
            radial-gradient(transparent 0%, transparent 0%);
        background-position: calc(100% - 20px) calc(1em + 6px),
            calc(100% - 15.5px) calc(1em + 6px), calc(100% - 0.5em) 0.5em;
        background-repeat: no-repeat;
        background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
        border: 0;
        border-radius: 5px;
        box-shadow: 5px 10px 20px #00000015;
        box-sizing: content-box;
        font-size: 18px;
        height: 32px;
        outline: none;
        padding: 10px 14px;
    }

    .address-error {
        background-color: $mf-purple-1;
        border-radius: 12px;
        margin-bottom: 10px;
        margin-left: -22px;
        margin-right: -22px;
        padding-left: 22px;
        padding-right: 22px;
        padding-top: 26px;
    }
}

.fifty-form {
    display: flex;
    flex-direction: row;

    input,
    select {
        width: 170px;
    }
}

.request-form-wrapper {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: auto;
    padding: 60px;

    button {
        margin-bottom: 66px;
        width: 100%;
    }

    .schedule {
        display: flex;
        flex-direction: row;
    }

    .sixty-form input {
        width: 204px;
    }

    .forty-form input {
        width: 136px;
    }

    .error-msg {
        bottom: 15px;
        color: #263b59;
        font-weight: 800;
        left: 5px;
        margin-top: 12px;
        position: relative;
        size: 20px;
    }
}

.confirm-container {
    box-sizing: border-box;
    height: 100%;
    line-height: 28px;
    overflow: auto;
    padding: 120px 90px;

    .line {
        background: $mf-white;
        border: 1.5px solid $mf-white;
        margin: 50px 0;
        opacity: 0.5;
        width: 65px;
    }

    .info-container {
        margin: 20px 0;
    }

    .icon {
        margin-bottom: 18px;
    }
}

.button-container {
    padding-top: 40px;

    button {
        margin: 9px 0;
    }
}

.bold {
    font-weight: bold;
    margin-bottom: 10px;

    span {
        font-weight: bold;
    }
}