@import '@app/mf-nucleus.scss';

button {
    border: unset;
    box-shadow: -5px 9px 37px -15px $mf-button-shadow;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    outline: none;

    >img {
        vertical-align: middle;
    }
}

.color-primary {
    background-color: $mf-purple-3;
    color: $mf-white;

    &:hover:not(.disabled) {
        background-color: #223652;
    }

    &:active:not(.disabled) {
        background-color: #20334d;
    }
}

.color-secondary {
    background-color: $mf-purple-2;
    color: $mf-white;

    &:hover:not(.disabled) {
        background-color: #6876de;
    }

    &:active:not(.disabled) {
        background-color: #5a66c2;
    }
}

.disabled {
    background-color: $mf-white;
    color: $mf-disabled-button;
    cursor: default;
    opacity: 25%;
}

.size-small {
    border-radius: 30px;
    font-size: 16px;
    height: 40px;
    line-height: 27px;
}

.size-default {
    border-radius: 30px;
    font-size: 24px;
    height: 60px;
    line-height: 33px;
}

.size-large {
    border-radius: 30px;
    font-size: 28px;
    height: 78px;
    line-height: 38px;
}

.width-compact {
    padding: 0 30px;
}

.width-stretch {
    min-width: 160px;
    padding: 0 60px;
}