@import '@app/mf-nucleus.scss';

a {
    text-decoration: none;
}

.content-sb {
    display: flex;
    justify-content: space-between;

    ul {
        list-style: none;
    }

    h1 {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
    }
}

.cur-pointer {
    cursor: pointer;
}

.common-button {
    color: $mf-black;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    height: 60px;
    line-height: 30px;
    margin-right: 10px;
    min-width: 102px;
    padding: 15px 28px;
    position: relative;

    &:hover,
    &.clicked {
        background: $mf-white;
        border-radius: 30px;
        box-shadow: 0 9px 37px -15px $mf-purple-2;
        color: $mf-purple-2;
    }
}

.common-button-style:hover>span {
    opacity: 1;
}

.ta-center {
    text-align: center;
}

.display-flex {
    display: flex;
}

.fc-black-26 {
    color: $mf-purple-3;
}

.main-title-style {
    font-weight: 800;
}

.poa {
    position: absolute;
}

.por {
    position: relative;
}

.alert-count {
    align-items: center;
    background-color: $mf-purple-2;
    border-radius: 50%;
    color: $mf-white;
    display: flex;
    font-size: 10px;
    font-weight: 800;
    justify-content: center;
    line-height: 10px;
    min-height: 1em;
    min-width: 1em;
    opacity: 0.6;
    padding: 3px;
    padding: 0.3rem;
    right: 10px;
    top: 10px;
    transition: 0.3s;
}

.w120 {
    width: 120px;
}

.ml66 {
    margin-left: 66px;
}

.mt36 {
    margin-top: 36px;
}

.mt56 {
    margin-top: 56px;
}

.mr66 {
    margin-right: 66px;
}

.position-right {
    position: absolute;
    right: 0;
}