/*********************
  VARIABLES
*********************/
$mf-white: #fff;
$mf-black: #000;
$mf-purple-1: #919dfa;
$mf-purple-2: #7988ff;
$mf-purple-3: #263b59;
$mf-purple-4: #d3d8ff;
$mf-purple-5: #8694ff;
$mf-purple-6: #afb8ff;
$mf-purple-7: #d3d8ff4c;

$mf-gray-1: #4d4d4d;
$mf-gray-2: #f8f8f8;

$mf-box-shadow: #00000015;
$mf-button-shadow: #080a16;
$mf-disabled-button: #00000050;
$mf-dismiss-shadow: #00000024;

$mf-text-dark: #1a202c;

$mf-background-1: #f6f7fb;

$mobile: 600px;
$tablet: 960px;
$desktop: 961px;

/*********************
  BREAKPOINTS
*********************/
@mixin breakpoint($point) {
 @if $point == mobile {
  @media (max-width: $mobile) {
   @content;
  }
 } @else if $point == tablet {
  @media (max-width: $tablet) {
   @content;
  }
 } @else if $point == desktop {
  @media (min-width: $desktop) {
   @content;
  }
 }
}

:export {
 $mf-white: $mf-white;
 $mf-black: $mf-black;
 $mf-purple-1: $mf-purple-1;
 $mf-purple-2: $mf-purple-2;
 $mf-purple-3: $mf-purple-3;
 $mf-purple-4: $mf-purple-4;
 $mf-purple-5: $mf-purple-5;
 $mf-purple-6: $mf-purple-6;
 $mf-purple-7: $mf-purple-7;

 $mf-gray-1: $mf-gray-1;
 $mf-gray-2: $mf-gray-2;

 $mf-box-shadow: $mf-box-shadow;
 $mf-button-shadow: $mf-button-shadow;
 $mf-disabled-button: $mf-disabled-button;
 $mf-dismiss-shadow: $mf-dismiss-shadow;

 $mf-text-dark: $mf-text-dark;

 $mf-background-1: $mf-background-1;

 $mobile: $mobile;
 $tablet: $tablet;
 $desktop: $desktop;
}
