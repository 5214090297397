.container {
 display: flex;
 height: 100vh;
 margin: 0;
 padding: 0;
 width: 100%;

 .flex-item {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
 }
}
