@import '@app/mf-nucleus.scss';

.register-form-wrapper {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: auto;
    padding: 60px;
}

.register-form {
    align-items: center;
    color: $mf-white;
    display: inline-block;
    height: fit-content;
    justify-content: center;
    width: 407px;

    button {
        height: 60px;
    }

    a {
        color: $mf-white;
        font-weight: bold;
        text-decoration: none;
    }

    input,
    select {
        margin: 20px 10px 20px 0;
        width: 379px;
    }
}

.fifty-form {
    display: flex;
    flex-direction: row;

    input,
    select {
        width: 170px;
    }
}

.register-form select {
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, $mf-purple-3 50%),
        linear-gradient(135deg, $mf-purple-3 50%, transparent 50%),
        radial-gradient(transparent 0%, transparent 0%);
    background-position: calc(100% - 20px) calc(1em + 6px),
        calc(100% - 15.5px) calc(1em + 6px), calc(100% - 0.5em) 0.5em;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
    border: 0;
    border-radius: 5px;
    box-shadow: 5px 10px 20px #00000015;
    box-sizing: content-box;
    font-size: 18px;
    height: 32px;
    outline: none;
    padding: 10px 14px;
}

.quarter-form {
    display: flex;

    input {
        box-shadow: none;

        /* temporary css for radio btn */
        width: 65px;
    }

    >div {
        align-items: center;

        /* temporary css for radio btn */
        display: flex;
    }
}

.sixty-form input {
    width: 204px;
}

.forty-form input {
    width: 136px;
}

.register-form-wrapper button {
    margin: 20px 0;
    width: 100%;
}

.schedule {
    display: flex;
    flex-direction: row;
}

.error-msg {
    bottom: 15px;
    color: $mf-purple-3;
    font-weight: 800;
    left: 5px;
    margin-top: 12px;
    position: relative;
    size: 20px;
}

.buttons {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 13px;
}

.register-form .address-error {
    background-color: $mf-purple-1;
    border-radius: 12px;
    margin-bottom: 10px;
    margin-left: -22px;
    margin-right: -22px;
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 26px;
}