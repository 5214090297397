@import '@app/mf-nucleus.scss';

.header-wrapper {
    display: flex;
    width: 100%;
}

@include breakpoint(tablet) {
    .header-wrapper {
        background: $mf-background-1;
    }
}