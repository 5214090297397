.terms-row {
 display: flex;
}

.terms-checkbox {
 flex-shrink: 0;
 margin-right: 16px;
}

.terms-info {
 flex-grow: 1;
}
