.privacy-policy-container {
 display: flex;
 flex-direction: column;
 margin: auto;

 .header div {
  font-size: 40px;
  font-weight: 700;
  margin: 20px 0;
 }

 .body {
  display: contents;
 }

 .body span {
  font-size: 24px;
  line-height: 48px;
  margin: 20px 0;
 }
}
