.form {
 display: flex;
 flex-direction: column;
 justify-content: flex-end;
}

.tax-rate {
 background: none;
 color: inherit;
 font: inherit;
 padding: 0;
 text-decoration-line: underline;
 text-decoration-style: dotted;
 text-decoration-thickness: 1px;
}
