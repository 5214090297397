@import '@app/mf-nucleus.scss';

.progress-bar {
    margin-top: 32px;
}

.bolded {
    font-weight: bold;
}

.section {
    padding-bottom: 20px;
    padding-top: 20px;
}

.section>button {
    margin-right: 12px;
}

.title {
    margin-bottom: 4px;
}

.body {
    margin-bottom: 50px;
}

.body:not(:last-child) {
    margin-bottom: 20px;
}

.divider {
    background: $mf-white;
    border: 1px solid $mf-white;
    border-radius: 1px;
    margin: 25px 0;
    width: 65px;
}

.buttons {
    margin-top: 32px;
}

.submit-invoice-button {
    width: 240px;
}

.terms-row {
    display: flex;
}

.terms-checkbox {
    flex-shrink: 0;
    margin-right: 16px;
}

.terms-info {
    flex-grow: 1;
}