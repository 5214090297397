@import '@app/mf-nucleus.scss';

.checkbox {
    appearance: none;
    background: $mf-white;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14);
    cursor: pointer;
    height: 25px;
    outline: none;
    width: 25px;

    &:checked {
        background: $mf-purple-2 url('./images/check.svg') no-repeat center;
        background-size: 16px;
    }

    &:disabled {
        background-color: #666;
        cursor: not-allowed;
    }
}