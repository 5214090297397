.root {
 height: 100%;
 width: 100%;
}

.container {
 display: flex;
 flex-direction: row;
 margin: 0;
 min-height: 100vh;
 padding: 0;
 width: 100%;
}

.left-column {
 flex-basis: 0;
 flex-grow: 7;
 flex-shrink: 0;
}

.content {
 height: 100%;
}

.right-column {
 flex-basis: 0;
 flex-grow: 3;
 flex-shrink: 0;

 .content {
  height: 100%;
  width: inherit;
 }
}

/* Small Screens */
@media screen and (max-width: 1000px) {
 .left-column {
  min-width: 100%;
 }

 .hide-left-column-on-narrow-viewport {
  display: none;
 }

 .right-column {
  min-width: 100%;
 }
}

/* Big Screens */
@media screen and (min-width: 1000px) {
 .with-separator {
  border-left: 3px solid #f1f1f1;
 }
}
