@import '@app/mf-nucleus.scss';

.box {
    padding: 64px 48px;

    >div>div {
        font-weight: 600;
    }

    span {
        font-size: 20px;
        line-height: 32px;
    }

    .sub-box {
        flex: 1;
    }
}

.how-it-works-wrapper {
    max-width: 1248px;
    text-align: center;

    button {
        border-radius: 32px;
        box-shadow: none;
        font-size: 20px;
        height: 64px;
        margin: 33px 0;
        max-width: 287px;
        width: 100%;
    }

    .text-large div {
        font-size: 40px;
        line-height: 56px;
        white-space: pre-line;
    }
}

.boxes {
    margin: 50px 0;

    .icon-container {
        background: $mf-background-1;
        border-radius: 32px;
        height: 64px;
        margin: 0 auto;
        margin-bottom: 32px;
        width: 64px;
    }
}

.boxes .box {
    padding: 64px 48px;

    >div>div {
        font-weight: 600;
    }

    span {
        font-size: 20px;
        line-height: 32px;
    }

    .sub-box {
        flex: 1;
    }
}

@include breakpoint(tablet) {
    .how-it-works-wrapper {
        .text-large {
            div {
                font-size: 35px;
                line-height: 45px;
                margin-bottom: 10px;
            }
        }

        span {
            font-size: 20px !important;
            line-height: 40px !important;
        }

        .boxes {
            .box {
                padding: 32px 36px;
            }
        }

        button {
            margin-top: 32px;
            min-width: calc(100% - 72px);
        }
    }
}

@include breakpoint(mobile) {
    .how-it-works-wrapper {
        text-align: initial;

        .icon-container {
            margin: initial;
            margin-bottom: 32px;
        }

        .boxes {
            .box {
                padding: 5px 36px;
            }
        }

        button {
            max-width: 100%;
        }
    }
}