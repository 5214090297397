@import '@app/mf-nucleus.scss';

.root {
    border: 0;
    border-radius: 5px;
    box-shadow: 5px 10px 20px $mf-box-shadow;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    height: 32px;
    margin: 5px 10px 5px 0;
    outline: none;
    padding: 10px 14px;

    &:disabled.color-secondary {
        background-color: $mf-purple-5;
        cursor: default;
    }

    &:disabled.color-primary {
        color: #b8b6b6;
        cursor: default;
        font-weight: 600;
    }
}

.full-width {
    width: 100%;
}

.color-primary {
    background-color: $mf-white;

    &[type='button'] {
        background-color: #d3d3d3;
    }

    &:hover[type='button'] {
        background-color: $mf-white;
    }

    &:focus[type='button'] {
        background-color: #808080;
    }

    &:hover:not(:disabled[type='button']),
    &:active:not(:disabled[type='button']) {
        background-color: $mf-white;
    }
}

.color-secondary {
    background-color: $mf-purple-6;
    color: $mf-white;

    &:hover:not(:disabled[type='button']),
    &:active:not(:disabled[type='button']) {
        background-color: $mf-purple-6;
    }
}

.hidden {
    display: none;
}

::placeholder {
    color: #ccc;
}

.error-border {
    border: 2px solid #263b59;
}