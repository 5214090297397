@import '@app/mf-nucleus.scss';

.container {
    background-color: $mf-purple-2;
    height: 100%;
}

.left-column {
    background-color: $mf-white;
    border-radius: 0 30px 30px 0;
    height: 100%;
    padding-right: 25px;

    .content {
        height: 100%;
        width: 100%;
    }
}

.adornment-position {
    bottom: 0;
    height: 393px;
    left: 0;
    margin-left: 91px;
    margin-right: 91px;
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 500px;

    .adornment-offset {
        left: 193px;
        position: relative;
        top: calc(-100% + 115px);
    }
}

.right-column {
    background-color: $mf-purple-2;
    height: 100%;
}