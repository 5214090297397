@import '@app/mf-nucleus.scss';

.container {
    background: rgba(211, 216, 255, 0.3);
    border-radius: 10px;
    width: 745px;

    .inline {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 8px;
        padding: 16px;
    }

    .link {
        color: $mf-purple-2;
        font-style: normal;
        font-weight: 800;
        text-decoration: none !important;
    }

    .dismiss-button {
        float: right;
        padding-right: 12px;
    }
}