.root {
 height: 100%;
 width: 100%;
}

/* Small Screens */
@media screen and (max-width: 1000px) {
 .left-column {
  padding: 0 16px;
 }

 .right-column {
  padding: 0 16px;
 }
}

/* Big Screens */
@media screen and (min-width: 1000px) {
 .left-column {
  /* TODO: min width */
  padding: 0 96px;
 }

 .right-column {
  padding: 0 64px;
 }
}
