@import '@app/mf-nucleus.scss';

.container {
  height: 100%;
}

.sidebar {
  background: $mf-purple-2 url('./images/background.svg') no-repeat -35px 100%;
  display: flex;
  height: 100%;
  left: -25px;

  /**
   * The following allows us to bleed the sidebar shoulder
   * into the content by 25px.
   */
  position: relative;
  width: calc(100% + 25px);
}

.sidebar-left-column {
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.sidebar-shoulder {
  animation: sidebarSlideInShoulder 0.5s forwards;
  animation-delay: 0.2s;

  /* left: -10px; */
  background-color: $mf-white;
  border-radius: 0 30px 30px 0;
  box-shadow: 40px 0 40px rgba(0, 0, 0, 0.1);
  padding-right: 25px;
  position: relative;

  &:focus {
    outline: none;
  }
}

.sidebar-shoulder-initial {
  animation: sidebarSlideInShoulder 0.5s forwards;
  animation-delay: 0.2s;
  height: 100%;
  width: 100%;
}

.sidebar-shoulder-final {
  width: 0%;
}

.sidebar-shoulder-hide {
  animation: sidebarSlideOutShoulder 0.5s forwards;
}

.sidebar-panel-initial {
  animation: sidebarSlideInPanel 0.5s forwards;
  animation-delay: 0.2s;
  overflow: hidden;
  visibility: hidden;
  width: 0;
}

.sidebar-panel-final {
  overflow: visible;
  visibility: visible;
  width: 100%;
}

.sidebar-panel-hide {
  animation: sidebarSlideOutPanel 0.5s forwards;
}

.sidebar-content-container {
  height: 100vh;
  overflow-y: auto;

  /* Header Text */
  .header {
    display: flex;
  }

  .title {
    flex-grow: 1;
  }
}

.sidebar-content-container::-webkit-scrollbar {
  background: transparent;
  /* Optional: just make scrollbar invisible */
  width: 0;
  /* Remove scrollbar space */
}

.sidebar-content {
  padding: 50px;
}

.dismiss-button {
  margin: 0 16px;
}

/* keyframes */
@keyframes sidebarSlideInShoulder {
  100% {
    width: 0%;
  }
}

@keyframes sidebarSlideInPanel {
  100% {
    overflow: visible;
    visibility: visible;
    width: 100%;
  }
}

/* on hide */
@keyframes sidebarSlideOutShoulder {
  0% {
    width: 0%;
  }

  100% {
    border-radius: 0;
    padding: 0;
    width: 100%;
  }
}

@keyframes sidebarSlideOutPanel {
  100% {
    overflow: hidden;
    visibility: hidden;
    width: 0%;
  }
}