@import '@app/mf-nucleus.scss';

.login-form-wrapper {
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 60px;

    .login-form {
        align-items: center;
        color: $mf-white;
        display: inline-block;
        height: fit-content;
        justify-content: center;
        margin-top: 60px;
        width: 407px;
    }

    .error {
        bottom: 15px;
        color: #ffb6c1;
        left: 5px;
        position: relative;
    }

    input {
        margin: 20px 10px 20px 0;
        width: 379px;
    }

    button {
        margin: 20px 0;
        width: 100%;
    }

    a {
        color: $mf-white;
        font-weight: bold;
        text-decoration: none;
    }
}