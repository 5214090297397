@import '@app/mf-nucleus.scss';

.form {
    position: fixed;
    top: 0;
    z-index: 100;
}

.list-item {
    background-color: $mf-white;
    border-radius: 17px;
    box-shadow: 0 0 63px -7px rgba(0, 0, 0, 0.2);
    color: $mf-gray-1;
    flex-direction: row;
    justify-content: space-between !important;
    max-height: 150px;
    max-width: 745px !important;
    padding: 20px !important;

    button {
        align-items: center;
        display: flex;
    }

    button div {
        margin: 0 5px 0 -5px;
    }
}

.unblurred-list {
    z-index: 101;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.left-box {
    border-radius: 10px;
    height: 60px;
    line-height: 19.5px;
    margin-right: 20px !important;
    width: 60px;
}

.list,
.footer {
    padding: 32px 0;

    .footer-link {
        color: inherit;
        text-decoration: inherit;
    }
}

.spacer {
    height: 32px;
}

.divider {
    border-top: 3px solid $mf-white;
    width: 100%;
}