@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;600;700&display=swap');
body {
 font-family: Montserrat, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 margin: 0;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
