@import '@app/mf-nucleus.scss';

.bar-container {
    display: flex;
    height: 6px;
    margin: 12px -8px 0;
    width: 100%;

    .bar {
        border-radius: 3px;
        flex-grow: 1;
        height: 6px;
        margin: 0 8px;
        opacity: 0.2;
    }

    .primary-bar {
        background: $mf-purple-2;
    }

    .secondary-bar {
        background: $mf-white;
    }

    .bar-focused {
        opacity: 1;
    }
}